import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavController, NavParams } from '@ionic/angular';

import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

export interface IPayloadBase {
  _id?: string,
  createdAt?: string,
  updatedAt?: string,
}


export interface IAPIResponse<T extends (IPayloadBase | IPayloadBase[])> {
  status: number;
  count: number;
  payload: T;
}

export interface IPerson extends IPayloadBase {
  _id?: string,
  email: string,
  password?: string,
}


type IPersonResponse = IAPIResponse<IPerson>;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  me$ = this.api.createApiSubject<IPersonResponse>(`${environment.api_url}auth/me`);

  constructor(
    private http: HttpClient, 
    private storage: StorageService,
    private router: Router,
    private api: ApiService,
    ) { }

  public login({email, password}) {
    return this.http.post<IAPIResponse<{token: string} & IPerson>>(`${environment.api_url}auth/login`, {
      email: email, 
      password: password,
    }).pipe(
      tap(({payload = {}}) => this.storeToken(payload.token)),
      tap((resp) => this.me$.setValue(resp))
    );
  }

  public register(person: IPerson) {
    return this.http.post<IAPIResponse<{token: string} & IPerson>>(`${environment.api_url}auth/signup`, person)
      .pipe(
        tap(({payload = {}}) => this.storeToken(payload.token)),
        tap(resp => this.me$.setValue(resp))
      );
  }

  public async getToken() {
    return await this.storage.get(ACCESS_TOKEN_KEY);
  }

  private async storeToken(accessToken) {
    await this.storage.set(ACCESS_TOKEN_KEY, accessToken);
  }

  public async closeSession() {
    await this.storage.clear();
    this.api.resetAll();
    await this.router.navigateByUrl('/auth', { replaceUrl: true });
  }
}
