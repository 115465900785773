import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthService, IPerson } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  me?: Partial<IPerson> = { };

  public appPages = [
    { title: 'Gateways', url: '/gateways', icon: 'radio' },
    { title: 'Devices', url: '/devices', icon: 'hardware-chip' },
  ];

  constructor(
    private auth: AuthService,
    private menu: MenuController,
    private router: Router,
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        menu.enable(!event.url.startsWith('/auth'));
      }
    });

    this.auth.me$.asObservable()
      .subscribe({
        next: resp => { this.me = resp?.payload ?? { }; },
        error: err => { }
      });
  }

  async logout() {
    await this.auth.closeSession();
  }
}
