import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { StorageService } from './storage.service';


export class ApiSubject<T> extends ReplaySubject<T> {
  private _value: T;
  get value() { return this._value; }
  async setValue(value: T) {
    this._value = value;
    this.next(value);
    await this.storage.set(this.CACHE_KEY, value);
  }

  private readonly _req = this.http.get<T>(this.endpoint);

  private readonly CACHE_KEY = `cache.${this.endpoint}`;

  constructor(
    private http: HttpClient, 
    private storage: StorageService, 
    private endpoint: string,
    ) {
    super(1);
    this.initFromCache();
  }

  asObservable() {
    this.init()
    return super.asObservable();
  }

  subscribe(...args) {
    this.init();
    return super.subscribe(...args);
  }

  private init() {
    if (!this._value) {
      this.refresh();
    }
  }

  private async initFromCache() {
    let cacheValue = await this.storage.get(this.CACHE_KEY) as T;
    if (cacheValue) {
      await this.setValue(cacheValue);
    }
  }

  async refresh() {
    let resp = await this._req.toPromise();
    await this.setValue(resp);
    return resp;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _apiSubjects: ApiSubject<any>[] = [];

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    ) { }

  createApiSubject<T>(endpoint) {
    const newSubject = new ApiSubject<T>(this.http, this.storage, endpoint);
    this._apiSubjects.push(newSubject);
    return newSubject;
  }

  resetAll() {
    this._apiSubjects.forEach(subject => {
      try {
        // subject.unsubscribe();
        subject.setValue(null);
      } catch {}
    });
  }
}
