import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, LogedinGuard } from './route-guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'gateways',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule),
    canActivate: [LogedinGuard]
  },
  {
    path: 'devices',
    loadChildren: () => import('./devices/devices.module').then( m => m.DevicesPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'gateways',
    loadChildren: () => import('./gateways/gateways.module').then( m => m.GatewaysPageModule),
    canActivateChild: [AuthGuard]
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
