import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null;
  private _storageReady: Promise<any>;

  constructor(
    private storage: Storage,
    private platform: Platform
    ) { 
    this._storageReady = this.init();
  }

  public async set(key: string, value: any) {
    await this._storageReady;
    return await this._storage?.set(key, value);
  }

  public async get(key: string) : Promise<any> {
    await this._storageReady;
    return await this._storage?.get(key);
  }

  public async remove(key: string) : Promise<any>{
    await this._storageReady;
    return await this._storage?.remove(key);
  }

  public async clear() {
    await this._storageReady;
    return await this.storage?.clear();
  }

  private async init() {
    this._storage = await this.storage.create();
  }
}
