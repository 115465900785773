import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) {}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes(environment.api_url)) {
            return from(this.auth.getToken()).pipe(
                switchMap(token => {
                    let newReq = req.clone({ 
                        ...(token ? {setHeaders: {"Authorization": `Bearer ${token}`}} : {}),
                    });
                    return next.handle(newReq);
                })
            );
        } else {
            return next.handle(req);
        }
    }
}

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
];